/** @format */

@tailwind components;
@tailwind base;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: Inter, sans-serif;
}

html,
body {
  /* @apply min-h-screen; */
  min-height: 100vh;
}

#__next {
  /* @apply min-h-screen; */
  min-height: 100vh;
  /*max-width: 1920px;*/
  margin: 0 auto;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.8);
}

.p-tooltip-text {
  background-color: #ffeee6 !important;
  color: #893f2b !important;
  font-size: 0.8rem !important;
}

.p-tooltip-arrow {
  border-block-color: #893f2b !important;
}
.container {
  max-width: 1280px !important;
}
.p-dialog-title {
  font-family: Inter, sans-serif !important;
  font-weight: 500 !important;
  font-size: 1.3rem !important;
}
.filter-orange {
  filter: invert(42%) sepia(100%) saturate(3990%) hue-rotate(2deg)
    brightness(105%) contrast(105%);
}
.filter-primary {
  filter: invert(7%) sepia(11%) saturate(4149%) hue-rotate(333deg)
    brightness(94%) contrast(97%);
}
.filter-secondary {
  filter: invert(94%) sepia(25%) saturate(148%) hue-rotate(337deg)
    brightness(89%) contrast(88%);
}
.swiper-pagination {
  position: relative !important;
  margin-top: 16px !important;
  left: 100px !important;
  transform: none !important;
  width: 125px !important;
}
.swiper-pagination .swiper-pagination-bullet {
  border-radius: 0;
  width: 40px;
  height: 3px;
  display: inline-block;
  background: #000000;
  margin: 0 5px;
  -webkit-transition: opacity 0.5s, background-color 0.5s, width 0.5s;
  -o-transition: opacity 0.5s, background-color 0.5s, width 0.5s;
  transition: opacity 0.5s, background-color 0.5s, width 0.5s;
  -webkit-transition-delay: 0.5s, 0.5s, 0s;
  -o-transition-delay: 0.5s, 0.5s, 0s;
  transition-delay: 0.5s, 0.5s, 0s;
}

.pagination-wrapper {
  /*  demo  */
  position: relative;
  display: flex;
  align-items: center;
  width: 77px;
}
.pagination span {
  display: inline-block;
  position: relative;
  width: 10px;
  height: 4px;
  opacity: 0.2;
  background-color: #000000;
  margin-right: 3px;
  -webkit-transition: opacity 0.5s, background-color 0.5s, width 0.5s;
  -o-transition: opacity 0.5s, background-color 0.5s, width 0.5s;
  transition: opacity 0.5s, background-color 0.5s, width 0.5s;
  -webkit-transition-delay: 0.5s, 0.5s, 0s;
  -o-transition-delay: 0.5s, 0.5s, 0s;
  transition-delay: 0.5s, 0.5s, 0s;
  top: -4px;
}
.pagination span.active {
  width: 35px;
  opacity: 1;
}

@media only screen and (max-width: 768px) {
  /* iframe[title="Button to launch messaging window, conversation in progress"] {
    bottom: 70px !important;
  } */
  iframe#launcher {
    bottom: 70px !important;
  }
}

.custom-select .p-dropdown-label {
  display: flex;
  align-items: center;
}
.custom-select .p-dropdown .p-dropdown-trigger {
  color: #893f2b;
}

.custom-select.small.p-dropdown .p-dropdown-trigger {
  width: 1.5rem;
}
.custom-select.medium.p-dropdown .p-dropdown-trigger {
  width: 2rem;
}
.custom-select.large.p-dropdown .p-dropdown-trigger {
  width: 3rem;
}

.custom-select.small.p-dropdown .p-dropdown-trigger .pi {
  font-size: 0.75rem;
  color: #893f2b;
}
.custom-select.medium.p-dropdown .p-dropdown-trigger .pi {
  font-size: 0.75rem;
  color: #893f2b;
}
.custom-select.large.p-dropdown .p-dropdown-trigger .pi {
  font-size: 1rem;
  color: #893f2b;
}

.custom-select.small .p-dropdown-label {
  font-size: 12px;
  padding: 0.25rem 0.5rem;
  line-height: 1.25rem;
}
.custom-select.medium .p-dropdown-label {
  font-size: 0.875rem;
  padding: 0.5rem 0 0.5rem 0.5rem;
  line-height: 1.25rem;
}
.custom-select.large .p-dropdown-label {
  font-size: 1rem;
  padding: 0.875rem 0 0.875rem 0.825rem;
  line-height: 1.25rem;
}
.custom-select.p-dropdown {
  border-radius: 0.375rem;
  box-shadow: none;
  border-color: #893f2b;
}
.custom-select.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none;
  border-color: #893f2b;
}
.custom-select.p-dropdown:not(.p-disabled):hover {
  border-color: #893f2b;
  box-shadow: none;
}
.custom-select-panel span {
  box-shadow: none;
}
.custom-select-panel.p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item.p-highlight {
  color: #893f2b;
  background-color: #ffeee6;
}
.custom-select-panel.small.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  /* padding: 8px 12px; */
  padding: 0.25rem 0.5rem;
  font-size: 12px;
}
.custom-select-panel.medium.p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item {
  padding: 0.5rem 0.5rem;
  font-size: 0.875rem;
}
.custom-select-panel.large.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  font-size: 1rem;
  padding: 0.75rem 0.825rem;
}
.custom-select-panel.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
  padding: 8px 12px;
}

input.remove-number-arrows::-webkit-outer-spin-button,
input.remove-number-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"].remove-number-arrows {
  -moz-appearance: textfield;
}

input[type="radio"]:checked.custom-radio-button {
  background-color: #893f2b;
  border-color: #893f2b;
}

input[type="radio"]:focus.custom-radio-button {
  outline: none;
  box-shadow: none; /* Adjust the color as needed */
}

input[type="radio"].custom-radio-button {
  accent-color: #893f2b; /* This property sets the color for the radio button in some modern browsers */
  cursor: pointer;
}

input[type="checkbox"]:checked.custom-checkbox {
  background-color: #893f2b;
  border-color: #893f2b;
}

input[type="checkbox"]:focus.custom-checkbox {
  outline: none;
  box-shadow: none; /* Adjust the color as needed */
}

input[type="checkbox"].custom-checkbox {
  cursor: pointer;
  accent-color: #893f2b; /* This property sets the color for the checkbox in some modern browsers */
}
